import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const SiennicaMuzeumSzkolnePage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Muzeum Szkolne w Siennicy" />
      <ReturnToPrevPage to={'/gminy/siennica'}>
        Materiały dotyczące gminy Siennica
      </ReturnToPrevPage>
      <Header1>Muzeum Szkolne w Siennicy</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={896591539} />
        <p>
          Sakralny zespół został wzniesiony w latach 1749 – 1760. Rola jego
          fundatorów przypadła wojewodzie mazowieckiemu Kazimierzowi
          Rudzińskiemu i jego żonie Antoninie z Nowosielskich Rudzińskiej.
          Kompleks powstał wg projektu architekta polskiego włoskiego
          pochodzenia Antonio Solariego (1700 – 1763). Co ciekawe pracował on
          również przy takich obiektach jak m.in. kościoły w Warce i Siedlcach,
          kolegiata w Łukowie, kolegium Pijarów w Radomiu oraz stołeczny
          klasztor sióstr Sakramentek. W stolicy Polski uczestniczył on ponadto
          przy przebudowie Zamku Królewskiego. 14 sierpnia 1761 przyniósł
          tymczasem konsekrację kościoła i klasztoru, które do 1864 roku
          znajdowały się w posiadaniu zakonu franciszkanów- reformatów.
        </p>
        <p>
          Zauważmy, że ten odłam franciszkanów wyłonił się w szesnastym stuleciu
          w wyniku ruchów reformatorskich mających miejsce w zakonie. Jego
          przedstawiciele przybyli do naszego kraju w siedemnastym wieku. W 1772
          roku w Polsce istniały cztery prowincje franciszkańsko – reformackie.
          Na ich obszarze funkcjonowało sześćdziesiąt klasztorów. 4 października
          1897 roku papież Leon XIII zlikwidował odrębności mające miejsce w
          obrębie zakonu franciszkanów. Po kasacji siennickiego klasztoru, która
          okazała się jedną z represji zastosowanych przez władze carskie po
          stłumieniu powstania styczniowego w budynku zlokalizowano seminarium
          nauczycielskie. Szybko, wbrew zamierzeniom władz rosyjskich, stało się
          ono miejscem rozwijania myśli o niepodległej Ojczyźnie. Warto dodać,
          że w latach 1859 i 1912 budynki uległy restauracji. Następnie, po
          zniszczeniu w 1944 roku przez wojska niemieckie odbudowano je, a
          wkrótce ponownie odnowiono w latach 1958 – 1959.
        </p>
        <p>
          Barokowy kościół pod wezwaniem Ofiarowania Najświętszej Maryi Panny
          złożony jest z czteroprzęsłowej, prostokątnej nawy i węższego od niej,
          prostokątnego, dwuprzęsłowego prezbiterium. Od niego wiedzie przejście
          do pomieszczeń poklasztornych i zakrystii. – Wewnątrz ściany nawy
          rozczłonkowane wydatnymi filarami, między którymi wysokie arkadowe
          wnęki o zaokrąglonych narożach. Filary opięte toskańskmi pilastrami
          dźwigającymi obiegające nawę i prezbiterium belkowanie z silnie
          wyładowanym gzymsem. Sklepienia kolebkowo – krzyżowe na zdwojonych
          gurtach. Łuk tęczy półkolisty wsparty na filarach. W tarczowych
          ścianach wnęk o zach. okna zamknięte odcinkowo. W pn. przęśle nawy
          murowany, podsklepiony chór muzyczny wsparty na trzech arkadach
          filarowych, z których środkowa zamknięta półepileptycznie z kratą
          ozdobioną elementami reformackimi, po poł. w. XVIII, boczne
          półkoliście. (…) (…)
        </p>
        <p>
          Nad belkowaniem o profilowanym gzymsie szczyt pilastrowy ze spływami
          bezwolutowymi i wazonami, zwieńczony odcinkowym przyczółkiem: na nim
          daty renowacji. Elewacja zach. rozczłonkowana parami lizen, gzymsem
          kordonowym i uproszczonym belkowaniem. Dachy dwuspadowe, kryte blachą.
          Na kalenicy nad nawą wieżyczka na sygnaturkę, kryta hełmem blaszanym –
          napisały w opisie kościoła Izabella Galicka i Hanna Sygietyńska. W
          kościele można obejrzeć liczne przedmioty noszące cechy zabytkowości.
          Wśród nich można wymienić m.in. osiemnastowieczny obraz Cudownego
          Pomnożenia Chleba, który znajduje się w ołtarzu głównym świątyni. Z
          tego samego stulecia pochodzą również chrzcielnica, kropielnica, dwie
          szafy zakrystyjne oraz srebrna tabliczka wotywna i rzeźba Chrystusa
          Zmartwychwstałego. Odwiedziny świątyni przynoszą także możliwość
          obejrzenia portretów wojewody Michała Rudzińskiego i jego żony
          kasztelanki słońskiej Elżbiety z Potockich. W kościele znajdują się
          również tablice epitafijne poświęcone świętemu Janowi Pawłowi II,
          podstolemu inflanckiemu Michałowi Babskiemu, regentowi grodzkiemu
          sieradzkiemu Józefowi Jaxa Leśniewskiemu, Franciszkowi Kopycińskiemu,
          Ludwice i Stanisławowi Dzwonkowskim, ks. Łukaszowi Janczakowi i
          poległemu w Powstaniu Warszawskim żołnierzowi Armii Krajowej starszemu
          strzelcowi Janowi Sado ps. „Twardy”.
        </p>
        <p>
          Kolejna z nich upamiętnia żołnierzy AK kapitana Tadeusza Kuryłka,
          starszego szeregowego Bolesława Duszczyka i szeregowego Stanisława
          Duszczyka, którzy zostali straceni 31 grudnia 1946 roku za działalność
          na rzecz niepodległego bytu państwa polskiego. Najmłodsza z tablic
          przypomina natomiast o rekonsekracji kościoła, konsekracji ołtarza
          pańskiego i poświęcenia zrekonstruowanej nadstawy ołtarza. Wydarzenie
          to miało miejsce 21 listopada 2018 roku. W zachodnią ścianę
          prezbiterium wmurowana jest ponadto urna z sercem Antoniego
          Rudzińskiego. Ważnym elementem wystroju kościelnego wnętrza jest także
          odtworzony obraz Matki Bożej Siennickiej. Jej oryginalny wizerunek
          znajdował się niegdyś w siennickiej świątyni i był czczony od lat
          dwudziestych XVII wieku. Była to stylizowana i słynąca cudami kopia
          obrazu Matki Boskiej Częstochowskiej. Posiadała srebrno – złotą
          koronkę i korony. Poddana renowacji i oprawiona w ramy w 1918 roku
          spłonęła ona w wyniku wojennego pożaru mającego miejsce 28 lipca 1944
          roku.
        </p>
        <p>
          Odtworzony obraz Matki Bożej Siennickiej został poświęcony 20
          listopada 2004 roku. Godny uwagi jest także czworoboczny, otoczony
          osiemnastowiecznym murem dziedziniec kościoła. Do charakterystycznych
          jego elementów należą barokowa brama zwieńczona półkolistym szczytem,
          usytuowana na toskańskiej kolumnie dziewiętnastowieczna figura Matki
          Boskiej Niepokalanej oraz okalający ją ponad dwustuletni murek
          ceglany. Obecnym proboszczem siennickiej parafii jest ksiądz Marek
          Kasprzak. Posługę duchową sprawuje w niej także ks. Dariusz Stonio.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/siennica/muzeum-szkolne/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/siennica/muzeum-szkolne/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SiennicaMuzeumSzkolnePage
